import React, { useState, useEffect, useRef } from "react";

import "./Loader.scss";

const Loader = () => {
  const [load, setLoad] = useState("0%");
  const [opacity, setOpacity] = useState("1");
  const [visable, setVisable] = useState("block");

  const loader = useRef(0);
  const overlay = useRef(0);

  useEffect(() => {
    window.addEventListener("load", () => {
      setLoad("100%");
    });
    return () => {
      window.removeEventListener("load", () => {
        setLoad("100%");
      });
    };
  }, []);

  useEffect(() => {
    let loaderTransition = loader.current;
    loaderTransition.addEventListener("transitionend", removeOverlay);

    return () => {
      loaderTransition.removeEventListener("transitionend", removeOverlay);
    };
  }, []);

  const removeOverlay = () => {
    setOpacity("0");
    overlay.current.ontransitionend = () => {
      setTimeout(function () {
        setVisable("none");
      }, 1000);
    };
  };

  return (
    <>
      <div
        ref={overlay}
        className="overlay-loader"
        style={{ opacity: opacity, display: visable }}
      >
        <div className="masked-wrapper">
          <div
            ref={loader}
            className="masked-loader"
            style={{ width: load }}
          ></div>
        </div>

        <div className="masked-outline"></div>
      </div>
    </>
  );
};

export default Loader;
