import "./app.css";
import Loader from "./Loader";
function App() {
  return (
    <>
      <div className="App">
        <div class="overlay"></div>
        <div class="stars" aria-hidden="true"></div>
        <div class="starts2" aria-hidden="true"></div>
        <div class="stars3" aria-hidden="true"></div>
        <main class="main">
          <div class="content">
            <h1>website under construction</h1>
            <h2>
              Exciting things are produced behind the scenes, our website is
              currently being recrafted in our hometown Amsterdam. Stay tuned
              for our updated version. In the meanwhile please reach out to{" "}
              <a
                style={{
                  color: "white",
                }}
                href="mailto:info@weproduzze.com"
              >
                info@weproduzze.com
              </a>{" "}
              for any inquiries, questions or just to say hi.
            </h2>

            <section class="social_icons">
              <a
                href="https://www.facebook.com/WPME22/"
                title="Facebook"
                target="_blank"
              >
                <i class="fa fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/wpme.group/?hl=nl"
                title="Instagram"
                target="_blank"
              >
                <i class="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCfRzuKNV-7sSC-PgHqSot6Q"
                title="Youtube"
                target="_blank"
              >
                <i class="fa fa-youtube"></i>
              </a>
            </section>
          </div>
        </main>
      </div>
      <Loader />
    </>
  );
}

export default App;
